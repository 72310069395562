<template>
  <b-modal
    id="modal-advanced-search"
    :title="$t('Pesquisa avançada')"
    no-close-on-backdrop
    hide-footer
    size="lg"
    scrollable
    centered
    @hidden="resetModal"
  >
    <b-container>
      <e-filters
        :searching="fetching"
        @search="filterData"
        @reset="resetFiltersLocal"
      >
        <b-row>
          <b-col md="8">
            <FormulateInput
              id="modalAdvProductName"
              v-model="filters.name"
              type="text"
              :label="$t('Nome')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="modalAdvProduct-brand"
              v-model="filters.brand"
              type="vue-select"
              :label="$t('Marca')"
              :placeholder="$t('Selecione uma marca')"
              :options="getComboBrands"
            />
          </b-col>
        </b-row>
      </e-filters>

      <b-card-actions
        :title="$t('Produtos encontrados')"
        :no-actions="getProducts.length === 0"
        :action-refresh="getProducts.length > 0"
        :show-loading="fetching"
        @refresh="filterData"
      >
        <div class="pb-1">
          <e-page-size-selector
            :per-page="paging.pageSize"
            @change="pageSizeChange"
          />
        </div>
        <b-table
          show-empty
          responsive
          striped
          class="bordered"
          :fields="fields"
          :items="getProducts"
        >
          <template #cell(imageSquare)="row">
            <b-img
              thumbnail
              :src="row.item.imageSquare || noImage"
              :alt="`Imagem ${row.item.name || 'sem imagem'}`"
              :style="{ height: 'auto', width: '150px', objectFit: 'contain' }"
            />
          </template>

          <template #cell(action)="row">
            <e-button
              variant="primary"
              :icon="'plus'"
              @click="addProduct(row.item)"
            />
          </template>
        </b-table>
        <b-row>
          <b-col cols="6">
            <e-pagination-summary
              :current-page="paging.currentPage"
              :per-page="paging.pageSize"
              :total="paging.rowCount"
              :total-on-page="paging.rowsInCurrentPage"
            />
          </b-col>
          <b-col cols="6">
            <b-pagination
              v-model="paging.currentPage"
              align="right"
              :total-rows="paging.rowCount"
              :per-page="paging.pageSize"
              aria-controls="client-table"
              @change="pageChange"
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </b-container>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol, BImg, BPagination, BTable } from 'bootstrap-vue'
import { formulateHelper } from '@/mixins'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { EPaginationSummary, EFilters } from '@/views/components'
import EButton from '@/views/components/EButton.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import noImage from '@/assets/images/noimage.png'
import delegablePermissions from '@/utils/delegable-permissions'
import EPageSizeSelector from '@/views/components/pagination/EPageSizeSelector.vue'

export default {
  components: {
    BModal,
    BContainer,
    BRow,
    BImg,
    BCol,
    BPagination,
    BTable,
    BCardActions,
    EPaginationSummary,
    EButton,
    EFilters,
    EPageSizeSelector,
  },

  mixins: [formulateHelper],

  data() {
    return {
      fetching: false,
      products: [],
      delegateUserData: null,
      noImage,
    }
  },

  computed: {
    ...mapState('app', ['modalAuthRef']),
    ...mapState('pages/pdv/payBox', ['clientForm', 'itemForm']),
    ...mapState('pages/pdv/saleProducts', {
      filters: 'filters',
      paging: 'paging',
      combos: 'productCombos',
    }),
    ...mapGetters('pages/pdv/saleProducts', [
      'getProducts',
      'getComboBrands',
      // 'getComboDepartments',
      // 'getComboLines',
      // 'getComboUnits',
      // 'getComboGroups',
      // 'getComboSubgroups',
    ]),
    ...mapGetters('pages/pdv/payBox', ['getItems']),
    fields() {
      return [
        {
          label: '#',
          key: 'imageSquare',
          class: 'text-center',
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          class: 'text-left',
        },
        {
          label: this.$t('Preço Unitário'),
          key: 'price',
          class: 'text-left',
          formatter: value => this.$options.filters.currency(value, null, 2),
        },
        {
          label: this.$t('Ação'),
          key: 'action',
          class: 'text-left',
        },
      ]
    },
  },

  async mounted() {
    await this.fetchProductCombos()
  },

  methods: {
    ...mapActions('pages/pdv/saleProducts', [
      'fetchProducts',
      'fetchProductByEan',
      'fetchProductCombos',
      'setCurrentPage',
      'setPageSize',
      'setComboSubgroups',
      'resetFilters',
    ]),
    ...mapActions('pages/pdv/saleCreditProducts', [
      'validateCreditKit',
    ]),

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    resetFiltersLocal() {
      this.resetFilters()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    // selectGroup(idValue) {
    //   this.filters.subgroup = null
    //   this.setComboSubgroups(idValue)
    // },

    async getData() {
      try {
        this.fetching = true

        await this.fetchProducts(this.delegateUserData)
      } catch (error) {
        this.showError({ message: 'Problemas ao pesquisar produtos', error })
      } finally {
        this.fetching = false
      }
    },

    async addProduct(productBasic) {
      try {
        this.fetching = true
        const product = await this.fetchProductByEan({
          ean: productBasic.ean,
          customerId: this.clientForm.id,
        })

        const { isValid, message } = await this.validateCreditKit({
          product,
          getItems: this.getItems || [],
          itemFormAmount: this.itemForm.amount,
        })

        if (!isValid) {
          this.showWarning({ message })
          return
        }

        this.$emit('add-product', product)
        this.hideModal()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    resetModal() {
      this.resetFilters()
      this.products = []
      this.delegateUserData = null
    },

    hideModal() {
      this.$bvModal.hide('modal-advanced-search')
    },

    async showModal() {
      try {
        this.delegateUserData = await this.modalAuthRef.auth(
          delegablePermissions.PDV_PRODUCT_ADVANCED_SEARCH
        )
        this.$bvModal.show('modal-advanced-search')
        setTimeout(() => {
          this.focusInput('#modalAdvProductName')
        }, 50)
      } catch (error) {
        /* eslint-disable no-console */
        console.error(error)
        if (error.message) {
          this.showWarning({ message: error.message })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: 0.2s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
