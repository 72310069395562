<template>
  <section>
    <layout-pay-box-section :with-header="false">
      <template #main>
        <b-tabs
          id="purchaseInfoTab"
          v-model="actualTab"
          fill
        >
          <b-tab
            id="purchase-tab-product"
            class="content-tab"
            :style="{ height: `${getHeightTabContent}px` }"
            :disabled="isConsignedSaleFinish"
          >
            <template #title>
              <p class="m-0">
                PRODUTO
                <e-shortcut :shortcuts="['F6']" />
              </p>
            </template>
            <product-tab ref="productTab" />
          </b-tab>

          <b-tab
            id="purchase-tab-client"
            class="content-tab"
            :style="{ height: `${getHeightTabContent}px` }"
          >
            <template #title>
              <p class="m-0">
                CLIENTE
                <e-shortcut :shortcuts="['F7']" />
              </p>
            </template>
            <client-tab ref="clientTab" />
          </b-tab>

          <b-tab
            id="purchase-tab-payment"
            class="content-tab"
            :style="{ height: `${getHeightTabContent}px` }"
          >
            <template #title>
              <p class="m-0 text-upper">
                PAGAMENTO
                <e-shortcut :shortcuts="['F10']" />
              </p>
            </template>
            <payment-tab ref="paymentTab" />
          </b-tab>
        </b-tabs>
      </template>

      <template #footer>
        <b-row class="h-100 d-flex align-items-center">
          <b-col>
            <e-button
              id="paybox_purchase-btn_cancel"
              :busy="isFinishingSale"
              variant="danger"
              :text="isExistingSale ? $t('SAIR') : $t('CANCELAR')"
              :icon="'x-circle'"
              size="sm"
              tabindex="-1"
              block
              :text-shortcuts="['F5']"
              @click="onCancel"
            />
          </b-col>
          <b-col v-if="false">
            <e-button
              id="paybox_purchase-btn_delivery"
              :busy="isFinishingSale"
              variant="dark"
              :text="$t('ENTREGA')"
              :icon="'truck'"
              size="sm"
              block
              :text-shortcuts="[]"
              @click="onShowDeliveryAddress"
            />
          </b-col>
          <b-col v-if="isConsignedSaleFinish">
            <e-button
              id="paybox_purchase-btn_items_return"
              :busy="isFinishingSale"
              variant="dark"
              :text="$t('DEVOLUÇÕES')"
              :icon="'arrow-clockwise'"
              size="sm"
              block
              @click="onShowReturnItems"
            />
          </b-col>
          <b-col>
            <e-button
              id="paybox_purchase-btn_finish"
              :busy="isFinishingSale"
              variant="primary"
              :text="tabEnum.payment === actualTab ? getFinishLabel : $t('PAGAMENTO')"
              :icon="'check-circle'"
              size="sm"
              block
              :text-shortcuts="['F10']"
              @click="onFinish"
            />
          </b-col>
        </b-row>
      </template>
    </layout-pay-box-section>

    <!-- <modal-finished
      v-show="saleFinished"
      ref="modalFinished"
      :data-finished="dataFinished"
      @click-next="onCleanSale"
    /> -->

    <modal-return-items
      ref="modalReturnItems"
      show-products
      :return-form="returnForm"
      :sale-data="getConsignedReturnData"
      @confirm="onConfimReturnForm"
    />

    <modal-delivery-address
      v-if="false"
      ref="modalDeliveryAddress"
      :delivery-form="deliveryForm"
      :address-list="clientForm.addressList"
      @click-confirm="onConfirmDeliveryAddress"
    />

    <modal-cold-option ref="modalColdOption" />
  </section>
</template>

<script>
import { BTabs, BTab, BRow, BCol } from 'bootstrap-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { app, keyboardCode, loadingOverlay, saleDomain, personTypes } from '@/mixins'
import EButton from '@/views/components/EButton.vue'
import EShortcut from '@/views/components/EShortcut.vue'
import delegablePermissions from '@/utils/delegable-permissions'
import EventBus from '@/utils/EventBus'
import LayoutPayBoxSection from './LayoutPayBoxSection.vue'
import ProductTab from './tabs/ProductTab.vue'
import ClientTab from './tabs/ClientTab.vue'
import PaymentTab from './tabs/PaymentTab.vue'
import ModalDeliveryAddress from './ModalDeliveryAddress.vue'
import ModalReturnItems from './modals/ModalReturnItems.vue'
import ModalColdOption from './modals/ModalColdOption.vue'

export default {
  components: {
    LayoutPayBoxSection,
    BTabs,
    BTab,
    BRow,
    BCol,
    EButton,
    ProductTab,
    ClientTab,
    PaymentTab,
    EShortcut,
    ModalDeliveryAddress,
    ModalReturnItems,
    ModalColdOption,
  },

  mixins: [app, keyboardCode, loadingOverlay, saleDomain, personTypes],

  props: {
    getHeight: {
      type: Number,
      required: true,
    },
    tabHeight: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      saleFinished: false,
      dataFinished: {
        date: new Date(),
      },
      actualTab: 0,
      delegateUserData: null,
      tabEnum: {
        product: 0,
        client: 1,
        payment: 2,
      },
      isFinishingSale: false,
      limitExceeded: 0.0,
    }
  },

  computed: {
    ...mapState('app', ['modalAuthRef']),
    ...mapState('pages/pdv/payBox', [
      'items',
      'returnForm',
      'clientForm',
      'paymentForm',
      'paymentStarted',
      'deliveryForm',
      'consignedMaxReturnValue',
      'echopeClassification',
      'invoiceReturn',
      'orderChanged',
      'coldOption',
    ]),
    ...mapGetters('pages/pdv/payBox', [
      'getConsignedReturnData',
      'getItems',
      'getCustomerDocument',
      'getCustomerName',
      'getTotalSale',
      'isOrder',
      'isBarConsumption',
      'hasNewPalmBeerOperation',
      'isConsignedSaleFinish',
      'isConsignedSalePayment',
      'hasActiveTefCardPayment',
      'getElectronicInvoiceRequired',
      'clientHasValidAddressToInvoice',
      'hasColdOptionProducts',
    ]),
    ...mapGetters('pages/pdv/payBoxConfiguration', ['currentPayboxConfiguration', 'isKioskStore']),
    ...mapGetters('pages/pdv', ['getInvoiceLimits']),

    getHeightTabContent() {
      // subtract title tabs
      return this.getHeight - this.tabHeight
    },

    getFinishLabel() {
      let label = this.$t('FINALIZAR')
      if (this.isConsignedSalePayment) label = this.$t('REGISTRAR PAGAMENTO')
      return label
    },

    isExistingSale() {
      return this.isOrder || this.isBarConsumption
    },
  },

  watch: {
    // actions when change tab
    async actualTab(newVal, oldVal) {
      if (newVal === oldVal) return
      this.removeKeyEvent(this.$refs.paymentTab.paymentShortcut)
      this.$refs.productTab.endProductTabEvents()

      if (newVal === this.tabEnum.product) {
        this.$refs.productTab.startProductTabEvents()
      } else if (newVal === this.tabEnum.client) {
        this.$refs.clientTab.onFocusDocument()
      } else if (newVal === this.tabEnum.payment) {
        this.stStartPayment()
        this.addKeyEvent(this.$refs.paymentTab.paymentShortcut)
      }
    },
  },

  created() {
    this.addKeyEvent(this.purchaseInfoShortcuts)
    EventBus.$on('client-tab-select', () => {
      this.actualTab = this.tabEnum.client
    })
    EventBus.$on('payment-tab-select', () => {
      this.actualTab = this.tabEnum.payment
    })
  },

  mounted() {
    if (this.isConsignedSaleFinish || this.isConsignedSalePayment) {
      setTimeout(() => {
        this.actualTab = this.tabEnum.payment
        this.addKeyEvent(this.$refs.paymentTab.paymentShortcut)
      }, 300)
      return
    }

    this.$refs.productTab.startProductTabEvents()
  },

  beforeDestroy() {
    this.removeKeyEvent(this.purchaseInfoShortcuts)
    if (this.$refs.paymentTab?.paymentShortcut) {
      this.removeKeyEvent(this.$refs.paymentTab.paymentShortcut)
    }

    this.$refs.productTab.endProductTabEvents()
  },

  methods: {
    ...mapActions('pages/pdv', ['updateTotalCashInPdv']),
    ...mapActions('pages/pdv/payBox', {
      stCleanPayBox: 'cleanState',
      stFinishSale: 'finishSale',
      stStartPayment: 'setStartPayment',
      stSetDeliveryData: 'setDeliveryData',
      stSetReturnForm: 'setReturnForm',
      stAddTempDelegation: 'AddTempDelegation',
      stCancelIncompleteInvoice: 'cancelIncompleteInvoice',
      stSetElectronicInvoiceRequired: 'setElectronicInvoiceRequired',
    }),

    clientIsAnonymous() {
      return this.clientForm.personType === this.personTypeEnum.CONSUMER
    },

    clientIsE3() {
      const { E3 } = this.echopeClassificationEnum

      return this.echopeClassification === E3
    },

    async filterLimit(limits, model, state) {
      const result = limits.filter(limit => limit.model === model && limit.originState === state)
      return result.length > 0 ? JSON.parse(JSON.stringify(result[0])) : null
    },

    async invoiceExceededLimits() {
      await this.stSetElectronicInvoiceRequired(false)

      const { store: payBoxStore, preferredInvoiceType } = this.currentPayboxConfiguration
      const limits = await this.filterLimit(
        this.getInvoiceLimits,
        preferredInvoiceType,
        payBoxStore?.address?.province
      )

      if (limits != null && limits.maximumTotal != null && limits.maximumTotal > 0) {
        if (
          limits.maximumTotalWithouIdentification != null &&
          this.getTotalSale > limits.maximumTotalWithouIdentification &&
          this.getTotalSale < limits.maximumTotal
        ) {
          this.limitExceeded =
            limits.maximumTotalWithouIdentification > 0
              ? limits.maximumTotalWithouIdentification
              : limits.maximumTotal
          return true
        }
        if (this.getTotalSale > limits.maximumTotal) {
          this.limitExceeded =
            limits.maximumTotalWithouIdentification > 0
              ? limits.maximumTotalWithouIdentification
              : limits.maximumTotal
          await this.stSetElectronicInvoiceRequired(true)
          return true
        }
      }

      return false
    },

    async onShowReturnItems() {
      this.$refs.modalReturnItems.showModalReturn()
    },
    async onConfimReturnForm(returnForm) {
      try {
        if (returnForm.value > this.consignedMaxReturnValue) {
          const confirmOverflowSaleReturn = await this.confirm({
            text: this.$t('Valor a ser retornado ultrapassou o total permitido.'),
            allowOutsideClick: false,
            allowEscapeKey: true,
            focusCancel: true,
          })
          if (!confirmOverflowSaleReturn) return

          if (!this.$can('SaleReturnCanOverflowMaxValue', 'PayBoxSale')) {
            const delegateUserData = await this.modalAuthRef.auth(
              delegablePermissions.PDV_SALE_RETURN_OVERFLOW_MAX_VALUE
            )
            this.stAddTempDelegation(delegateUserData)
          }
        }

        await this.stSetReturnForm({ returnForm })
        this.$refs.modalReturnItems.hideModal()
      } catch (error) {
        this.showError({ error })
      }
    },

    async onShowDeliveryAddress() {
      this.$refs.modalDeliveryAddress.showModal()
    },
    async onConfirmDeliveryAddress(deliveryForm) {
      this.stSetDeliveryData({ isDelivery: true, deliveryForm })
    },

    async onCancel({ forceExit }) {
      try {
        // TODO: Devemos validar se o usuário realizou as devoluções ? Não adicionei pois não sei ainda oq fazer no fluxo onde não houve devoluções

        if (!this.isOrder && this.hasActiveTefCardPayment) {
          this.showError({
            title: this.$t('Pagamentos TEF'),
            message: this.$t('PAY_BOX_SALE.CANCEL.CONFIRMATION_TEF_PAYMENT_CARD_ACTIVE'),
          })
          this.actualTab = this.tabEnum.payment
          return
        }

        if (!forceExit) {
          let actionMsg = this.$t('Deseja realmente cancelar esta venda?')
          if (this.isOrder)
            actionMsg = this.$t('Deseja sair da venda? As alterações feitas serão perdidas.')
          if (this.isBarConsumption)
            actionMsg = this.$t(
              'Deseja sair do fechamento da comanda? As alterações feitas serão perdidas.'
            )

          const confirm = await this.confirm({
            text: actionMsg,
            focusCancel: true,
          })
          if (!confirm) return

          switch (true) {
            case this.isOrder && !this.orderChanged:
              break
            default:
              this.delegateUserData = await this.modalAuthRef.auth(delegablePermissions.PDV_CANCEL)
          }
        }

        if (this.invoiceReturn && this.invoiceReturn?.model === 'NFE') {
          const delegateUserData = await this.modalAuthRef.auth(
            delegablePermissions.ERP_INVOICE_MANAGE
          )
          this.stAddTempDelegation(delegateUserData)
        }
        await this.stCancelIncompleteInvoice()

        const isReturnToPendingSale = this.isOrder
        const isReturnToBarTabs = this.isBarConsumption
        await this.onCleanSale()

        if (isReturnToPendingSale) this.$router.push({ name: 'pdv-pay-box-delivery-sales' })
        else if (isReturnToBarTabs) this.$router.push({ name: 'pdv-pay-box-bar-tab-management' })
        else this.showSuccess({ message: this.$t('Venda cancelada') })
      } catch (error) {
        console.error(error)
        if (error.message) {
          this.showWarning({ message: error.message })
        } else {
          this.showError({ error })
        }
      }
    },

    async onFinish() {
      if (this.actualTab !== this.tabEnum.payment) {
        this.actualTab = this.tabEnum.payment
        return
      }
      const validationError = { message: '' }
      const invoiceExceededValueLimits = await this.invoiceExceededLimits()
      if (this.items.length === 0)
        validationError.message = this.$t('Não foi adicionado nenhum item')
      else if (this.paymentForm.balance > 0)
        validationError.message = this.$t('Há recebimentos faltando no pagamento')
      else if (!this.$refs.clientTab.validate()) {
        if (this.hasNewPalmBeerOperation) {
          validationError.message = this.$t(
            'Cliente é obrigatório quando há produto do tipo crédito.'
          )
        } else {
          validationError.message = this.$t('Dados incorretos no formulário de clientes')
          this.actualTab = this.tabEnum.client
        }
      } else if (this.isConsignedSaleFinish) {
        if (this.returnForm.returnedItems?.length > 0 && this.returnForm.paymentMethod == null) {
          validationError.message = this.$t('A forma de pagamento da devolução deve ser informada.')
        }
      } else if (invoiceExceededValueLimits) {
        if (this.clientIsAnonymous()) {
          validationError.message = this.$t(
            'Cliente é obrigatório para emissão de cupom fiscal devido ao valor total da venda'
          )
        } else if (this.clientIsE3()) {
          validationError.message = this.$t(
            `O limite de valor para vendas sem identificação do cliente é de R$ ${this.$options.filters.currency(
              this.limitExceeded
            )}. Por favor, altere a Classificação echope do pedido.`
          )
        } else if (!this.clientHasValidAddressToInvoice) {
          validationError.message = this.$t(
            'Cliente informado não possui endereço ou o mesmo é inválido! Devido ao valor total da venda o endereço é uma informação obrigatória!'
          )
        }
      }

      if (!validationError.message) {
        validationError.message = this.validateCustomer()
      }

      if (validationError.message) {
        this.showWarning({ message: validationError.message })
        return
      }

      let messages = {
        confirm: { title: this.$t('Finalizar a venda?') },
        overlay: this.$t('Finalizando venda'),
        successToast: { title: this.$t('Venda finalizada') },
      }

      if (this.isConsignedSalePayment) {
        messages = {
          confirm: { title: this.$t('Registrar o pagamento?') },
          overlay: this.$t('Registrando pagamento'),
          successToast: { title: this.$t('Pagamento registrado') },
        }
      }

      const confirm = await this.confirm(messages.confirm)

      if (confirm) {
        this.saleFinished = true

        try {
          this.isFinishingSale = true

          if (invoiceExceededValueLimits && this.getElectronicInvoiceRequired === true) {
            await this.showInformation({
              text: this.$t(
                'O valor da venda ultrapassa o limite permitido para emissão do cupom fiscal.' +
                  'Uma Nota Fiscal Eletrônica será emitida! Ao finalizar a venda, realize a impressão da DANFe gerada para o cliente!'
              ),
            })
            const delegateUserData = await this.modalAuthRef.auth(
              delegablePermissions.ERP_INVOICE_GENERATE
            )
            this.stAddTempDelegation(delegateUserData)
          }

          const coldOption =
            this.coldOption ??
            (this.hasColdOptionProducts && !this.isKioskStore
              ? await this.$refs.modalColdOption.showModal()
              : null) ??
            this.coldOptionEnum.NATURAL

          this.showLoadingOverlay(messages.overlay)
          const resultFinishSale = await this.stFinishSale(coldOption)
          await this.onCleanSale()
          this.showSuccess({ ...messages.successToast, message: resultFinishSale.displayMessage })
        } catch (error) {
          this.showError({ error })
          const data = error.response?.data
          if (data?.Request && Array.isArray(data?.Request)) {
            this.showLoadingOverlay(this.$t('PAY_BOX_SALE.CLOSING_ALREADY_FINISHED_OVERLAY'))
            if (
              data.Request.includes('SALE_ORDER.FINISH_ERROR.SALE_ALREADY_COMPLETED_OR_CANCELED')
            ) {
              await this.onCancel({ forceExit: true })
            }
          }
        } finally {
          this.isFinishingSale = false
          this.hideLoadingOverlay()
        }

        try {
          await this.updateTotalCashInPdv()
        } catch (error) {
          this.showError({ error })
        }
      }
    },

    validateCustomer() {
      const needCustomer =
        this.getItems.some(i => i.product.customerRequired === true) ||
        this.getItems.some(
          i =>
            i.product.kitItems?.length > 0 &&
            i.product.kitItems.some(a => a && a.product.customerRequired)
        )

      if (needCustomer) {
        if (this.getCustomerDocument === '') {
          return this.$t('PAY_BOX_SALE.CUSTOMER.CUSTOMER_IS_REQUIRED_IN_SOME_ITENS')
        }

        if (this.getCustomerName === '' || this.getCustomerName === 'Não informado') {
          return this.$t('PAY_BOX_SALE.CUSTOMER.CUSTOMER_NAME_IS_REQUIRED')
        }
      }

      if (this.clientForm?.id === 1) {
        return null
      }

      const isPfWithName =
        this.clientForm?.personType === 'Individual' && this.clientForm?.name.length > 0
      if (isPfWithName) {
        const nameWords = this.clientForm?.name.split(' ')
        if (nameWords.length < 2) {
          return this.$t('PAY_BOX_SALE.CUSTOMER.CUSTOMER_FULL_NAME_IS_INVALID')
        }
      }

      const isPjWithCompanyName =
        this.clientForm?.personType === 'Juridical' && this.clientForm?.companyName.length > 0
      if (isPjWithCompanyName) {
        const companyNameWords = this.clientForm?.companyName.split(' ')
        if (companyNameWords.length < 2) {
          return this.$t('PAY_BOX_SALE.CUSTOMER.CUSTOMER_FULL_COMPANY_NAME_IS_INVALID')
        }
      }

      return null
    },

    async onCleanSale() {
      await this.stCleanPayBox()
      this.saleFinished = false
      this.actualTab = 0
      this.limitExceeded = 0.0
    },

    purchaseInfoShortcuts(e) {
      const setActualTab = val => {
        this.actualTab = val
      }

      const actionsF = {
        [this.keyCode.f5]: () => this.onCancel(),
        [this.keyCode.f6]: () => setActualTab(this.tabEnum.product),
        [this.keyCode.f7]: () => setActualTab(this.tabEnum.client),
        [this.keyCode.f10]: () => this.onFinish(),
      }

      if (actionsF[e.code]) {
        e.preventDefault()
        if (actionsF[e.code]) actionsF[e.code]()
      }
    },

    purchaseInfoRemoveEvents() {
      this.removeKeyEvent(this.purchaseInfoShortcuts)
      this.removeKeyEvent(this.$refs.productTab.productShortcut)
    },
    purchaseInforAddEvents() {
      this.addKeyEvent(this.purchaseInfoShortcuts)
      this.addKeyEvent(this.$refs.productTab.productShortcut)
    },
  },
}
</script>

<style lang="scss" scoped>
.content-tab {
  overflow-y: auto;
}

kbd {
  background-color: var(--dark);
}

.bold {
  font-weight: 600;
}
</style>
